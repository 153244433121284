<template>
    <div class="home">
        <div class="top flex">
            <img @click="goHome('')" class="logo" src="@/assets/home2/logo@2x.png" alt="">
            <div class="flex">
                <div class="nav" @click="goHome('#about')">关于赛事</div>
                <div class="nav" @click="goHome('#format')">赛事赛制</div>
                <div class="nav"  @click="goHome('#review')">评审介绍</div>
                <div class="nav" @click="goSign">赛事报名</div>
            </div>
        </div>
        <div class="crumbs">首页 > <span style="color: #45B79C">关于赛事</span></div>
        <div class="banner">关于赛事</div>
        <div class="content flex">
            <div class="left">
                <a style="text-decoration:none" :href='"#"+item.id' v-for="(item,index) in navList" :key="index" >
                <div  class="flex name" @click="active = item.id"  :class="[active == item.id ? 'name-active':'']">
                    <img v-if="active == item.id" :src="item.active" alt="">
                    <img v-else :src="item.icon" alt="">
                    {{item.name}}
                </div>
                </a>
            </div>
            <div class="right" id="right">
                <!-- 赛事目的 -->
                <div id="1" class="block1">
                    <div class="title"><span style="font-weight: bold">赛事目的</span> | purpose</div>
                    <div class="text" style="text-indent:3em">本次赛事致力于聚焦常熟文旅资源，打造常熟文化名片。赛事主要面向全国征集优秀文创设计作品，为提升常熟文化旅游聚集智慧。以提升常熟文创内涵及产品品质为基础，使创意设计与当地文旅产业需求相结合，开发创新一批承载常熟地域故事的文化创意产品，助推常熟文化旅游产业提档升级、促进常熟文化旅游高质量发展。</div>
                </div>
                <!-- 参赛须知 -->
                <div id="2" class="block2">
                    <div class="title"><span style="font-weight: bold">参赛须知</span> | Notice</div>
                    <div style="overflow-y: scroll;height: 260px" class="text">1. 作品应结合常熟文化元素创意创新，表现独特，有一定的艺术感、和纪念意义。作品精神需积极向上，符合相关法律法规要求。<br>
                    2. 参赛作品不得含有任何违反《中华人民共和国宪法》、《中华人民共和国著作法》及任何其他相关国家（地区）法律法规内容。<br>
                    3. 参赛者提交的参赛作品必须由参赛者本人创作或参与创作，应确认其作品的原创性，且著作权未移交他人；若在比赛或商业应用过程中发生著作权纠纷，其法律责任由参赛者本人承担。<br>
                    4. 如因参赛者的抄袭、侵权等行为所引起的法律责任由参赛者承担，大赛主办方有权在任何阶段单方面取消其参赛及获奖资格。如多人合作，需征得所有合作者同意并标明所有合作者姓名。所有投稿人均须填写承诺书，并承担相应的法律责任。<br>
                    5. 所有获奖的设计作品与形象口号的知识产权归主办方所有，大赛组委会有权利向知识产权局申请知识产权保护，参赛选手不能再次转让、出版或参加展览、比赛等，否则大赛组委会将追究其法律责任。<br>
                    6. 大赛主办方拥有根据竞赛进程与参赛作品的实际情况，有调整奖项数量、等级、取消或添加奖项的权利，对本次大赛和评奖保留最终的解释权。<br>
                    7. 当遇到以下情况时，大赛组委会有权收回奖杯、证书、标志使用权、奖金等：正式确认获奖作品侵犯了其他作品的知识产权；设计者在未通知主办方的情况下对获奖作品进行重大修改，并继续在该作品上使用获奖标志或利用其进行宣传。<br>
                    8. 参赛者必须保证在各方面均没有违反或侵犯他人的知识产权，包括商标、专利、著作权、多媒体、数据库等各方面，参赛者须严格遵守大赛处理侵权投诉的程序。<br>
                    9. 大赛组委会对参赛者提交的相关信息进行严密的管理。大赛组委会有权使用参赛者的信息进行与评奖活动有关的宣传活动，例如发布获奖作品信息、展览展示等。</div>
                </div>
                <!-- 赛事流程 -->
                <div id="3" class="block3">
                    <div class="title"><span style="font-weight: bold">赛事流程</span> | Process</div>
                    <div class="text">
                        <table cellspacing="0">
                            <tr>
                                <td style="width: 385px">赛事启动仪式 媒体发布会</td>
                                <td class="time" style="width: 306px">2021.12.28</td>
                            </tr>
                            <tr>
                                <td>赛事招募，作品创作、征集</td>
                                <td class="time">2022.1.17-4.22</td>
                            </tr>
                            <tr>
                                <td><span>作品初评阶段</span> <br>
                                    大赛组委会评出入围作品，并对入围者 发送《初评入围通知书》</td>
                                <td class="time">2022.4.23-4.30</td>
                            </tr>
                            <tr>
                                <td><span>作品终评阶段</span> <br>
                                    ①对入围作品进行公布网络评选+线下 专家评审，选出最终获奖作品<br>
                                    ②大众评审网络票选优秀奖作品</td>
                                <td class="time">2022.5.1-5.20</td>
                            </tr>
                            <tr>
                                <td>获奖作品整理&赛事颁奖仪式筹备</td>
                                <td class="time">2022.5.21-5.27</td>
                            </tr>
                            <tr>
                                <td>文创大赛颁奖仪式，全媒体奖项公布</td>
                                <td class="time">2022.5.28</td>
                            </tr>
                            <tr>
                                <td>常熟文创大赛作品特展</td>
                                <td class="time">2022.5.28-6.28</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- 赛事对象 -->
                <div id="4" class="block4">
                    <div class="title"><span style="font-weight: bold">赛事对象</span> | Object</div>
                    <div class="text">
                        1.视觉传达或工业（产品）设计专业高等院校师生；<br> 2. 独立设计师、艺术爱好者及创意团队； <br> 3. 具有文化创意设计、研发生产能力的企业及机构；<br>  4. 其他设计艺术爱好者。
                    </div>
                </div>
                <!-- 奖项设置 -->
                 <div id="5" class="block5">
                    <div class="title"><span style="font-weight: bold">奖项设置</span> | Awards</div>
                    <div class="text">
                   每组赛道<br>
                   赛道一：金奖1名，奖金30000元，优秀奖2名，奖金1000元 <br>赛道二：金奖1名，奖金20000元，优秀奖2名，奖金1000元 <br>赛道三：金奖1名，奖金20000元，优秀奖2名，奖金1000元<br> 赛道四：金奖3名，奖金5000元, 优秀奖9名，奖金1000元 <br>赛道五：入围奖3名，赛事奖品1份<br>
                   注:<br>
                   1.组委会初审将从投稿作品中根据赛道公平评选出若干入围作品进行评审委员会决赛作品投票及大众评审投票。<br> 2.决赛获奖作品评分规则：作品由专家评审打分决定。每条赛道按作品最终得分数评选出金奖及优秀奖名额。 <br>3.优秀奖奖项由大众评审打分决定，得票数排名居前者获胜；如与金奖获奖作品重复，则依投票总数排名顺延决出。
                    </div>
                </div>
                <!-- 参赛者权益 -->
                <div id="6" class="block6">
                    <div class="title"><span style="font-weight: bold">参赛者权益</span> | rights and interests</div>
                    <div class="text">
                    1. 应邀线下参与“物语常熟” 文创大赛颁奖仪式，并在后续特展拥有亮相机会。<br> 2.获奖及入围作品将收录进“物语常熟”文创特展（线上及线下展），获得媒体曝光机会。<br>  3.获奖作品评估后，有机会作为的常熟文化文创产品进行生产及售卖，获得销售分成和渠道支持。<br>  4. 入驻“物语常熟”文创设计人才库，赋能常熟文旅资源。
                    </div>
                </div>
            </div>
        </div>
        <!-- footer -->
        <div id="sign" class="footer">
        <img class="logobottom" src="@/assets/home1/logobottom@2x.png" alt="">
        <div class="text-wrap flex-vcbetween">
            <div>
            <div><span>指导单位</span>中共常熟市委宣传部</div>
            <div><span>主办单位</span>常熟文旅发展有限责任公司</div>
            <div><span>承办单位</span>吉捷文化传媒（上海）有限公司</div>
            </div>
            <div class="flex-vc">
            <div><span>官方热线:</span></div>
            <div>
                <div>邮箱: 962991363@qq.com</div>
                <div>微信: 13817518262</div>
            </div>
            </div>
        </div>
        <div class="text-bottom flex-between">
            <div class="fs13"><img class="c" src="@/assets/home1/C@2x.png" alt="">2021 <span style="font-weight: bold">changshustory.</span> ALL Right Reserved <a style="color:#666" href="https://beian.miit.gov.cn/">沪ICP备12038716号-10</a></div>
            <div class="fs15"><span>关于赛事</span>|<span>赛事赛制</span>|<span style="margin-right:0">评审介绍</span></div>
        </div>
    </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            navList: [
                {   id: 1,
                    name: '赛事目的',
                    icon: require('@/assets/aboutss/icon1@2x.png'),
                    active: require('@/assets/aboutss/icon1_active@2x.png')
                },
                {   id: 2,
                    name: '参赛须知',
                    icon: require('@/assets/aboutss/icon2@2x.png'),
                    active: require('@/assets/aboutss/icon2_active@2x.png')

                },
                {   id: 3,
                    name: '赛事流程',
                    icon: require('@/assets/aboutss/icon3@2x.png'),
                    active: require('@/assets/aboutss/icon3_active@2x.png')
                },
                {   id: 4,
                    name: '赛事对象',
                    icon: require('@/assets/aboutss/icon4@2x.png'),
                    active: require('@/assets/aboutss/icon4_active@2x.png')
                },            
                {   id: 5,
                    name: '奖项设置',
                    icon: require('@/assets/aboutss/icon5@2x.png'),
                    active: require('@/assets/aboutss/icon5_active@2x.png')
                },
                {   id: 6,
                    name: '参赛者权益',
                    icon: require('@/assets/aboutss/icon6@2x.png'),
                    active: require('@/assets/aboutss/icon6_active@2x.png')
                },
            ],
            active: 1
        }
    },
    methods: {
        goSign() {
            this.$router.push('/sign')
        },
        goHome(id){
            this.$router.push({name:'Home',query: {id: id}})
        },
        handleScroll() {
            let scrollTop = document.querySelector("#right").scrollTop;
            if(scrollTop <= 422) {
                this.active = 1
            } else if(scrollTop > 422 && scrollTop <= 888) {
                this.active = 2
            } else if(scrollTop > 888 && scrollTop <= 1632){
                this.active = 3
            } else if(scrollTop > 1632 && scrollTop <= 1986){
                this.active = 4
            } else {
                this.active = 5
            }
        }
    },
    mounted() {
        document.querySelector("#right").addEventListener('scroll',this.handleScroll)
    }
}
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  align-items: center;
}
.flex-vc {
  display: flex;
  justify-content: center;
}
.flex-vcbetween {
  display: flex;
  // align-items: center;
  justify-content: space-between;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home {
  margin: 0 auto;
  width: 1920px;
  .top {
    background: #FFFFFF;
    height: 134px;
    border-bottom: 1px solid #EBEBEB;
    .logo {
      width: 200px;
      margin-left: 360px;
      margin-right: 328.5px;
      cursor: pointer;
    }
    .nav:hover {
      color:#45B79C;
      font-weight: bold;
      cursor: pointer
    }
    .nav {
      width: 175px;
      font-size: 20px;
      position: relative;
      a {
        text-decoration: none;
        color: #333333;
      }
      span {
        position: absolute;
        left: 49.5px;
        top: 15px;
        display: block;
        width: 76px;
        height: 10px;
        background: #AAAAAA;
        z-index: -1;
      }
    }
  }
    .crumbs {
        width: 1200px;
        line-height: 76px;
        font-size: 20px;
        color: #231815;
        text-align: left;
        margin: 0 auto;
        
    }
    .banner {
        background: url('~@/assets/aboutss/banner@2x.png');
        width: 1920px;
        line-height: 330px;
        background-size: 100% 100%;
        font-size: 88px;
        color: #FFFFFF;
        font-weight: bold;
    }
    .content {
        width: 1200px;
        margin: 0 auto;
        // overflow-y: scroll;
        .left {
            width: 358px;
            height: 888px;
            background: #EEEFEF;
            text-align: left;
            padding-top: 40px;

            .name {
                font-size: 22px;
                color: #ABACB1;
                line-height: 62px;
                img {
                    width: 24px;
                    margin: 0 4px 0 34px;
                    display: inline-block;
                }
            }
            .name-active {
                background: #FFFFFF;
                color: #45B79C;
                font-weight: bold;
            }
        }
        .right {
            width: 842px;
            height: 888px;
            margin-left: 92px;
            text-align: left;
            overflow-y: scroll;
            .block1 {
                height: 382px;
                background: url('~@/assets/aboutss/block1bg@2x.png');
                background-size: 100% 100%;
                margin-top: 40px;
            }
            .block2 {
                height: 426px;
                background: url('~@/assets/aboutss/block2bg@2x.png');
                background-size: 100% 100%;
                margin-top: 40px;
            }
            .block3 {
                height: 704px;
                background: url('~@/assets/aboutss/block3bg@2x.png');
                background-size: 100% 100%;
                margin-top: 40px; 
                table {
                    font-size: 20px;
                    color: #231815;
                    line-height: 28px;
                }
                table, table td{
                    border-collapse:collapse;
                    border: 1px solid #45B79C;
                    padding: 15px 24px;
                    span {
                        font-weight: bold;
                        margin-bottom: 9px;
                        display: inline-block;
                    }
                    .time {
                        text-align: center;
                    }
                }
            }
            .block4 {
                height: 314px;
                background: url('~@/assets/aboutss/block4bg@2x.png');
                background-size: 100% 100%;
                margin-top: 40px;
            }
            .block5 {
                height: 672px;
                background: url('~@/assets/aboutss/block5bg@2x.png');
                background-size: 100% 100%;
                margin-top: 40px; 
            }
            .block6 {
                height: 410px;
                background: url('~@/assets/aboutss/block6bg@2x.png');
                background-size: 100% 100%;
                margin-top: 40px;            
            }

            .title {
                width: 750px;
                line-height: 62px;
                background: #45B79C;
                font-size: 24px;
                color: #FFFFFF;
                text-transform: uppercase;
                padding-left: 30px;
            }
            .text {
                font-size: 20px;
                color: #333333;
                line-height: 44px;
                padding: 35px 29px;
            }

        }
    }
  .footer {
    width: 1920px;
    height: 170px;
    background: #C1C1C1;
    position: relative;
    .logobottom {
      position: absolute;
      top: 38px;
      left: 860px;
      width: 200px;
    }
    .text-wrap {
      width: 1170px;
      margin: 0 auto ;
      color: #666666;
      font-size: 13px;
      line-height: 32px;
      text-align: left;
      padding-top: 30px;
      span {
        font-weight: bold;
        font-size: 15px;
        // color: #FFFFFF;
        margin-right: 16px;
        display: inline-block;
      }
    }
    .text-bottom {
      width: 1170px;
      margin: 0 auto;
      line-height: 32px;
      .fs13 {
        font-size: 13px;
        color: #666;
        img {
          width: 10px;
        }
      }
      .fs15 {
        font-size: 15px;
        color: #ABACB1;
        span {
          color: #666666;
          margin: 0 26px;
          display: inline-block;
        }
      }
    }
  }
}
</style>

